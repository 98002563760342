import { Drawer } from 'antd';
import MeterForm from './MeterForm';

function NewMeterForm(props) {
  return (
    <Drawer
      title={<b>New Meter</b>}
      placement="left"
      closable={false}
      visible={props.visible}
    >
      <MeterForm {...props} />
    </Drawer>
  );
}

NewMeterForm.defaultProps = {
  visible: false,
  marker: {},
  submit: () => {},
  cancel: () => {},
  newMarker: true
}

export default NewMeterForm;
