import React from 'react';
import ReactDOM from 'react-dom';
import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd.min.css';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ViewMap from './components/ViewMap/ViewMap';
import EditMap from './components/EditMap/EditMap';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<ViewMap />}/>
      <Route path="admin" element={<EditMap/>} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
