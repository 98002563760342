const baseUrl = 'https://esb-meter-locator-server-9ljbi.ondigitalocean.app';

const getMetersByBookID = async id => await fetch(`${baseUrl}/meter/book/${id}`, {
    method: "GET",
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });
  
const createMeter = async meter => await fetch(`${baseUrl}/meter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(meter),
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });

const editMeter = async meter => await fetch(`${baseUrl}/meter`, {
    method: "PUT",
    body: JSON.stringify(meter),
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });

const deleteMeter = async meter => await fetch(`${baseUrl}/meter`, {
    method: "DELETE",
    body: JSON.stringify(meter),
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .catch(error => {
    throw error
  });

export {
  createMeter,
  editMeter,
  getMetersByBookID,
  deleteMeter,
}