import { Input, AutoComplete } from 'antd';
import { useEffect, useState } from 'react';
import { createBooksObject } from '../../utils/books';

import './RouteSelector.css'

const renderTitle = (key, title) => (
  <span key={key}>
    {title}
  </span>
);

const renderItem = (key, route, day) => ({
  value: route,
  label: (
    <div
      key={key}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {route}
      <span>
        {day}
      </span>
    </div>
  ),
});

const processBooks = books => books.map((book, index) => 
  ({
    label: renderTitle(index, book.region),
    options: book.books.map((book, index) => renderItem(index, book.route, `Day ${book.day}`)),
  })
);


function RouteSelector(props) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(createBooksObject(props.books))
  }, [props.books]);

  const handleSearch = val => {
    setOptions(
      createBooksObject(
        props.books.filter(option => 
          option.region.includes(val) ||
          option.day.includes(val) ||
          option.route.includes(val)
        )
      )
    )
  }

  const setRoute = val =>
    props.onBookSelect(props.books.filter(book => book.route === val)[0])

  return (
    <div onClick={e => e.stopPropagation()}className='route-selector'>
      <AutoComplete
        dropdownMatchSelectWidth={320}
        style={{ width: 250 }}
        options={processBooks(options)}
        onSearch={handleSearch}
        onSelect={setRoute}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Input size="large" placeholder="Search route" onClick={e => {
          e.stopPropagation();
        }} />
      </AutoComplete>
      {props.book ? (
        <div className="ant-select-item" style={{
          background: 'white',
          margin: '0px',
          padding: '3px 12px',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          border: '1px solid #d1d1d1',
          fontSize: '12px'
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            {props.book.region}
            <span>Day {props.book.day}</span>
          </div>
          {props.markerCount ? (<div>
            {props.markerCount} meters
          </div>) : null}
        </div>
      ) : null}
      
    </div>
  );
}


export default RouteSelector;
