import { Tooltip } from 'react-leaflet';
import './CustomTooltip.css';

function CustomTooltip(props) {

  return (
      props.meterNumber ? (
        <Tooltip>{props.meterNumber}</Tooltip>
      ) : null
  );
}

export default CustomTooltip;
