import { useEffect } from 'react';
import { Button, Input, Radio, Form } from 'antd';
import './MeterForm.css';

function MeterForm(props) {
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [props.marker, props.visible, form]);
  
  const onFinish = values => props.submit({ ...props.marker, ...values });

  const closeForm = () => {
    props.cancel();
    form.resetFields();
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={props.marker}
      onFinish={onFinish}
    >
      <Form.Item 
        name="meterNumber"
        label="Meter Number"
      >
        <Input
          placeholder="Meter Number"
          controls={false}
          style={{ width: '100%' }}
          autoFocus
        />
      </Form.Item>
      <Form.Item 
        label="Meter Location"
        required
        name="meterLocation"
        rules={[
          {
            required: true,
            message: 'Meter Location is required.',
          },
        ]}
      >
        <Radio.Group value="inside" buttonStyle="solid">
          <Radio.Button value="inside">Inside</Radio.Button>
          <Radio.Button value="outside">Outside</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Meter Type"
        required
        name="meterType"
        rules={[
          {
            required: true,
            message: 'Meter Type is required.',
          }
        ]}
      >
        <Radio.Group buttonStyle="solid" name="meterType">
          <Radio.Button value="conventional">Conventional</Radio.Button>
          <Radio.Button value="smart">Smart</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>
      
      <Form.Item style={{float: 'right'}} >
        <Button onClick={closeForm}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

MeterForm.defaultProps = {
  visible: false,
  marker: {},
  submit: () => {},
  cancel: () => {},
  newMarker: true
}

export default MeterForm;
