import IS from '../images/insideRed.png';
import OS from '../images/outsideRed.png';
import OC from '../images/outsideBlue.png';
import IC from '../images/insideBlue.png';
import WOC from '../images/outsideYellow.png';
import WIC from '../images/insideYellow.png';
import Loc from '../images/location.png';
import New from '../images/newGreen.png';

import L from 'leaflet';
const iconSize = 28;

const warningInsideConventional = new L.Icon({
  iconUrl: WIC,
  iconSize,
});

const warningOutsideConventional = new L.Icon({
  iconUrl: WOC,
  iconSize,
});

const insideConventional = new L.Icon({
  iconUrl: IC,
  iconSize,
});

const outsideConventional = new L.Icon({
  iconUrl: OC,
  iconSize,
});

const insideSmart = new L.Icon({
  iconUrl: IS,
  iconSize,
});

const outsideSmart = new L.Icon({
  iconUrl: OS,
  iconSize,
});

const NewIcon = new L.Icon({
  iconUrl: New,
  iconSize,
});

const LocIcon = new L.Icon({
  iconUrl: Loc,
  iconSize: 40,
});

const getIcon = meter => {
  const isInside = meter.meterLocation === "inside";
  const isSmart = meter.meterType === "smart";
  const hasDescription = meter.description !== undefined;

  if (isSmart) {
    if (isInside) {
      return insideSmart;
    } else {
      return outsideSmart;
    }
  } else {
    if (hasDescription) {
      if (isInside) {
        return warningInsideConventional;
      } else {
        return warningOutsideConventional;
      }
    } else {
      if (isInside) {
        return insideConventional;
      } else {
        return outsideConventional;
      }
    }
  }
}

export {
  NewIcon,
  LocIcon,
  getIcon,
};