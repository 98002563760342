import { useEffect, useState } from 'react';
import { MapContainer, useMapEvents, Marker, TileLayer, LayersControl } from 'react-leaflet';
import { message, Modal } from 'antd';
import { createMeter, deleteMeter, editMeter, getMetersByBookID } from '../../api/meters';
import NewMeterForm from '../MeterForm/NewMeterForm';
import EditableMeterForm from '../MeterForm/EditableMeterForm';
import { getIcon, NewIcon } from '../../utils/marker';
import './EditMap.css';
import RouteSelector from '../RouteSelector/RouteSelector';
import { getEditableBooks } from '../../api/books';
import { maps } from '../../utils/maps';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const {BaseLayer} = LayersControl;

function EditMap() {
  const [ markers, setMarkers ] = useState([]);
  const [ draftMarker, setDraftMarker ] = useState();
  const [ editingMarker, setEditingMarker ] = useState();
  const [ books, setBooks ] = useState([]);
  const [ currentBook, setCurrentBook ] = useState();
  const [ignored, forForceUpdate] = useState(0);
  const forceUpdate = () => forForceUpdate(ignored + 1);

  useEffect(() => {
    getEditableBooks().then(res => 
      setBooks(res)
    ).catch(err =>
        message.error('Error loading books, please reload')
    );
  }, []);

  const setBook = book => {
    setCurrentBook(book);
    getMetersByBookID(book._id)
      .then(res => setMarkers(res))
      .catch(() => {
        setCurrentBook(undefined);
        message.error('Error loading meters, try again')
      });
  }

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        if(currentBook) {
          setDraftMarker(
            {
              latlng: e.latlng,
              meterType: 'conventional',
              meterLocation: 'outside',
              bookId: currentBook._id,
            }
          )
        } else {
          Modal.warning({
            title: 'No route selected',
            content: 'You must select a route to add markers',
            okText: 'Ok',
            onOk: () => {}
          });
        }
      }
    });
    return null;
  }

  const addMarker = (marker) => {
    createMeter(marker)
    .then((res) => {
        message.success('Meter added');
        setMarkers(old => [...old,
          {...marker, _id: res.insertedId}
        ]);
        setDraftMarker(undefined)
      })
      .catch(err => {
        message.error('Error adding meter');
      });
  };
  
  const editMarker = (marker) => {
    editMeter(marker)
    .then((res) => {
        message.success('Meter edited');
        let newArr = [...markers];
        newArr[markers.findIndex(({ _id }) => _id === marker._id)] = marker;
        setMarkers(newArr);
        setEditingMarker(undefined)
      })
      .catch(err => {
        message.error('Error editing meter');
      });
    };
    
    const deleteMarker = (marker) => {
      Modal.confirm({
        title: 'Delete Marker',
        content: 'Are you sure you want to delete this marker?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          deleteMeter(marker)
          .then((res) => {
            message.success('Meter deleted');
            let newArr = [...markers];
            newArr.splice(markers.findIndex(({ _id }) => _id === marker._id), 1);
            setMarkers(newArr);
            setEditingMarker(undefined)
          })
          .catch(err => {
            console.log(err)
            message.error('Error deleting meter');
          });
        },
        onCancel: () => {}
      });
    };
  
  const changeMarkerPosition = (marker, latlng) => {
    const newMarker = {...marker, latlng: {lat: latlng.lat, lng: latlng.lng}};
    Modal.confirm({
      title: 'Move Marker?',
      content: 'Are you sure you want to move this marker?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        editMeter(newMarker)
        .then((res) => {
          let newArr = [...markers];
          newArr[markers.findIndex(({ _id }) => _id === marker._id)] = newMarker;
          setMarkers(newArr);
          message.success('Meter moved');
        })
        .catch(err => {
          console.log(err)
          message.error('Error moving meter');
          forceUpdate();
        });
      },
      onCancel: () => {
        forceUpdate();
      }
    });
  }

  return (
    <div className='admin-container'>
      <RouteSelector visible books={books} onBookSelect={setBook} book={currentBook} markerCount={markers.length} />
      <MapContainer 
        center={[53.1424, -7.6921]}
        scrollWheelZoom 
        zoom={8}
        zoomControl={false}
        className="admin-map"
      >        
        <LayersControl>
          {maps.map((map, index) => (
            <BaseLayer key={index} checked={index === 0} name={map.name}>
              <TileLayer url={map.url} maxZoom={map.maxZoom} subdomains={maps[index].subdomains || ['a','b','c']}/>
            </BaseLayer>
            ))
          }
          <BaseLayer name="Google Roads">
            <ReactLeafletGoogleLayer apiKey='AIzaSyConPoYPqaDPUhy8SKU8x6X5ZaAnj6kZhg' type={'roadmap'} />
          </BaseLayer>
        </LayersControl>

        <MapClickHandler />

        <NewMeterForm
          visible={draftMarker !== undefined}
          marker={draftMarker}
          submit={addMarker}
          cancel={() => setDraftMarker(undefined)}
        />

        <EditableMeterForm
          visible={editingMarker !== undefined}
          marker={editingMarker}
          submit={editMarker}
          delete={deleteMarker}
          cancel={() => setEditingMarker(undefined)}
        />

        {
          markers.map(
            (marker, index) =>
            <Marker
              key={index + ignored}
              position={marker.latlng}
              icon={getIcon(marker)}
              draggable
              onMouseOver={e => e.target.openPopup()}
              eventHandlers={{
                click: () => setEditingMarker(marker),
                dragend: (e) => changeMarkerPosition(marker, e.target._latlng),
              }}
            >
              <CustomTooltip meterNumber={marker.meterNumber} description={marker.description} />
            </Marker>
          )
        }
        {
          draftMarker ? (
            <Marker
              position={draftMarker.latlng}
              icon={NewIcon}
            />
          ) : undefined
        }
      </MapContainer>
    </div>
  );
}

export default EditMap;
