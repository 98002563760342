const maps = [
  {
    name: 'Open Street Map',
    maxZoom: 19,
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'Google',
    maxZoom: 18,
    url: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomains: ['mt1','mt2','mt3'],
  },
  {
    name: 'Arcgis',
    maxZoom: 18,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  },
  {
    name: 'Carto',
    maxZoom: 18,
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  },
];

export { maps };