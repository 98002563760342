import { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, LayersControl } from 'react-leaflet';
import { message } from 'antd';
import RouteSelector from '../RouteSelector/RouteSelector';
import { getMetersByBookID } from '../../api/meters';
import { getBooks } from '../../api/books';
import { getIcon } from '../../utils/marker';
import { maps } from '../../utils/maps';
import './ViewMap.css';
import { LocationMarker } from '../LocationMarker/LocationMarker';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import CustomPopup from '../CustomPopup/CustomPopup';

const {BaseLayer} = LayersControl;

function ViewMap() {
  const [ markers, setMarkers ] = useState([]);
  const [ books, setBooks ] = useState([]);
  const [ currentBook, setCurrentBook ] = useState();

  useEffect(() => {
    getBooks().then(res => 
      setBooks(res)
    ).catch(err =>
        message.error('Error loading books, please reload')
    );
  }, []);

  const setBook = book => {
    setCurrentBook(book);
    getMetersByBookID(book._id)
      .then(res => setMarkers(res))
      .catch(() => {
        setCurrentBook(undefined);
        message.error('Error loading meters, try again')
      });
  }

  return (
    <div className='admin-container'>
      <RouteSelector visible books={books} onBookSelect={setBook} book={currentBook} />
      <MapContainer 
        center={[53.1424, -7.6921]}
        scrollWheelZoom 
        zoom={8}
        zoomControl={false}
        className="admin-map"
      >        
        {/* <LocationMarker tracking={false} /> */}

        <LayersControl>
          {maps.map((map, index) => (
            <BaseLayer key={index} checked={index === 0} name={map.name}>
              <TileLayer url={map.url} maxZoom={map.maxZoom} subdomains={maps[index].subdomains || ['a','b','c']}/>
            </BaseLayer>
            ))
          }
          <BaseLayer name="Google Roads">
            <ReactLeafletGoogleLayer apiKey='AIzaSyConPoYPqaDPUhy8SKU8x6X5ZaAnj6kZhg' type={'roadmap'} />
          </BaseLayer>
        </LayersControl>

        {
          markers.map(
            (marker, index) =>
            <Marker
              key={index}
              position={marker.latlng}
              icon={getIcon(marker)}
            >
              <CustomPopup meterNumber={marker.meterNumber} description={marker.description} />
            </Marker>
          )
        }
      </MapContainer>
    </div>
  );
}

export default ViewMap;
