import { Popup } from 'react-leaflet';
import './CustomPopup.css';

function CustomPopup(props) {
  return (
      props.meterNumber || props.description ? (
        <Popup closeButton={false}>
          <div style={{
            textAlign: "center",
          }}>
            
            <div style={{
              fontWeight: "bold",
              fontSize: "22px"
            }}>
              {props.meterNumber}
            </div>
            <span 
              style={{
                fontSize: "15px",
                marginBottom: "20px"
              }}
            >
              {props.description}
            </span>
          </div>
        </Popup>
      ) : null
  );
}

export default CustomPopup;
