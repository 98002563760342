import { Drawer } from 'antd';
import { Button } from 'antd';
import MeterForm from './MeterForm';

function EditableMeterForm(props) {
  return (
    <Drawer
      title={<b>Edit Meter</b>}
      placement="right"
      closable={false}
      visible={props.visible}
    >
      <MeterForm {...props} />
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          padding: '10px 24px',
          background: '#fff',
        }}
      >
        <Button type='danger' onClick={() => props.delete(props.marker)}>Delete</Button>
      </div>
    </Drawer>
  );
}

EditableMeterForm.defaultProps = {
  visible: false,
  marker: {},
  submit: () => {},
  cancel: () => {},
  newMarker: true
}

export default EditableMeterForm;
