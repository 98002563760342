const baseUrl = 'https://esb-meter-locator-server-9ljbi.ondigitalocean.app';

const getBooks = async () => await fetch(`${baseUrl}/book`, {
    method: "GET",
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });

const getEditableBooks = async () => await fetch(`${baseUrl}/book/edit`, {
    method: "GET",
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });
  
const createBook = async book => await fetch(`${baseUrl}/book`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(book),
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });

const editBook = async book => await fetch(`${baseUrl}/book`, {
    method: "PUT",
    body: JSON.stringify(book),
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .catch(error => {
    throw error;
  });

const deleteBook = async book => await fetch(`${baseUrl}/book`, {
    method: "DELETE",
    body: JSON.stringify(book),
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .catch(error => {
    throw error
  });

export {
  getBooks,
  getEditableBooks,
  createBook,
  editBook,
  deleteBook,
}