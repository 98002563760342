
const getBookRegions = booksArr => {
  const allBookNames = 
    booksArr
      .map(book => book.region)
      .sort();
  return allBookNames.filter((book, index) => allBookNames.indexOf(book) === index);
}

const getBooksForRegion = (booksArr, region) => 
  booksArr.filter(b => b.region === region).sort((a, b) => a.route - b.route).sort((a, b) => a.day - b.day);

const createBooksObject = booksArr => testFilter(getBookRegions(booksArr)).map(region => ({region, books: getBooksForRegion(booksArr, region)}))

const testFilter = booksArr => 
  window.location.hostname === "localhost" ? booksArr.filter(b => b.includes("Keelan")) : booksArr.filter(b => !b.includes("Keelan"))

export {createBooksObject}